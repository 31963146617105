import React from "react";
import './testimonials.css'
import AVTR1 from "../../assets/yoko.jpg"
import AVTR2 from "../../assets/keshav.jpg"
import AVTR3 from "../../assets/taakayuki.jpg"
import AVTR4 from "../../assets/kunal.jpg"
// import Swiper core and required modules
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


const data = [
    {
        id: 1,
        avatar: AVTR1,
        name: "Yoko Sassanbe",
        review: "Sumit is a very dedicated professional. He has fixed some critical issues for our application in days just before our launch. Looking forward to working with him again",


    },
    {
        id: 2,
        avatar: AVTR3,
        name: "Taakayuki",
        review: "Sumit was involved in designing our AWS cloud infrastructure from scratch. Our system manages more than 300 reqs/second without any downtime. His efforts were invaluable.  ",


    },
    {
        id: 3,
        avatar: AVTR4,
        name: "Kunal Sahu",
        review: "Sumit developed a cricket ball by ball prediction model for me. He was able to achieve good accuracy within a week . This helped me get the top grade in my Machine Learning class",


    },
    {
        id: 4,
        avatar: AVTR2,
        name: "Keshav Gupta",
        review: "Sumit was involved in a ML team which developed our Exact Product Matching Model. We experimented with many different model architectures before settling with the final one. His wrote a custom loss function based on contrastive loss that improved our accuracy by 10%",


    }

]

const Testimonials = () => {
    return (
        <section id="testimonials">
            <h5>Review from clients</h5>
            <h2>Testimonials</h2>
            <Swiper className="container testimonials__container"
            modules={[ Pagination]}
            spaceBetween={40}
            slidesPerView={1}
            
            pagination={{ clickable: true }}
            >
                {
                    data.map(({id, name, avatar, review})=>{
                        return (
                            <SwiperSlide key={id} className="testimonial">
                    <div className="client__avatar">
                        <img src={avatar} />
                        
                    </div>
                    <h5 className="client__name">{name}</h5>
                        <small className="client__review">{review}</small>
                </SwiperSlide>
                        )
                    })
                }
                
                
            </Swiper>
        </section>
    )
}

export default Testimonials
