import React from "react";
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {BsLinkedin} from 'react-icons/bs'

import {BsWhatsapp} from 'react-icons/bs'
import  { useRef } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(process.env.REACT_APP_EMAILSERVICEID, process.env.REACT_APP_EMAILTEMPLATEID, form.current, process.env.REACT_APP_EMAILPUBLICKEY)
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
  };
    return (
        <section id="contact">
            <h5>Get in Touch</h5>
            <h2>Contact Me</h2>
            <div className="container contact__container">
                <div className="contact__options">
                    <article className="contact__option">
                        <MdOutlineEmail className="contact__option-icon"/>
                        <h4>Email</h4>
                        <h5>rsumit123@gmail.com</h5>
                        <a href="mailto:rsumit123@gmail.com" target = "_blank">Send an Email</a>

                    </article>
                    <article className="contact__option">
                        <BsLinkedin className="contact__option-icon"/>
                        <h4>LinkedIn</h4>
                        <h5>rsumit123</h5>
                        <a href="https://linkedin.com/in/rsumit123" target = "_blank">Send a Message</a>

                    </article>
                    <article className="contact__option">
                        <BsWhatsapp className="contact__option-icon"/>
                        <h4>Whatsapp</h4>
                        <h5>+917077856293</h5>
                        <a href="https://api.whatsapp.com/send?phone=+917077856293" target = "_blank">Send a message</a>

                    </article>


                </div>
                <form ref={form} onSubmit={sendEmail}>
                    <input type="text" name="name" placeholder="Your Full Name" required />
                    <input type="email" name="email" placeholder="Your Email" required />
                    <textarea name="message" rows="7" placeholder="Your Message" required ></textarea>
                    <button type="submit"  className="btn btn-primary">Send Message</button>

                </form>
            </div>
        </section>
    )
}

export default Contact