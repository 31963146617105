import React from "react";
import './footer.css'
import {FiInstagram} from 'react-icons/fi'
import {IoLogoTwitter} from 'react-icons/io'
import {BsLinkedin} from 'react-icons/bs'


const Footer = () => {
    return (
        <footer>
            <a href="#" className="footer__logo">SUMIT KUMAR</a>
            <ul className="permalinks">
                <li><a href="#">Home</a></li>
                <li><a href="#about">About</a></li>
                <li><a href="#experience">Experience</a></li>
                <li><a href="#services">Services</a></li>
                <li><a href="#portfolio">Portfolio</a></li>
                <li><a href="#testimonials">Testimonials</a></li>
                <li><a href="#contact">Contact</a></li>
                

            </ul>
            <div className="footer__socials">
                <a href="https://linkedin.com/in/rsumit123"><BsLinkedin/></a>
                <a href="https://twitter.com/rsumit123"><IoLogoTwitter/></a>
                <a href="https://instagram.com/rsumit123"><FiInstagram/></a>
            </div>
            <div className="footer__copyright">
                <small> &copy; SUMIT KUMAR. All Rights Reserved</small>
            </div>
        </footer>
    )
}

export default Footer