import React from "react";
import { FaAward } from "react-icons/fa";
import './about.css'
// import ME from "../../assets/me-about.jpg"
import ME from "../../assets/profile.png"
import {FiUsers} from "react-icons/fi";
import {VscFolderLibrary} from "react-icons/vsc";

const About = () => {
    return (
        <section id="about">

        <h5>Get To Know</h5>
        <h2>About Me</h2>
        <div className="container about__container">
            <div className="about__me">
                <div className="about__me-image">
                    <img src={ME} alt="About image"/>



                </div>
            </div>

            <div className="about__content">
            <div className="about__cards">
                <article className="about__card">
                    <FaAward className="about__icon"/>
                    <h5>Experience</h5>
                    <small>5+ years working</small>
                </article>
                <article className="about__card">
                    <FiUsers className="about__icon"/>
                    <h5>Clients</h5>
                    <small>20+ clients worldwide</small>
                </article>
                <article className="about__card">
                    <VscFolderLibrary className="about__icon"/>
                    <h5>Projects</h5>
                    <small>40+ completed projects</small>
                </article>
                </div>
                <p> I am a seasoned technology professional specializing in Data Engineering, Full Stack Development, DevOps, and Machine Learning, with extensive experience in the ecommerce sector. My technical expertise encompasses Python, React JS, Django, and various AWS cloud services. As a graduate with a Bachelor of Technology in Computer Science and Engineering, I combine a solid academic background with practical skills to architect solutions that are not only innovative but also highly effective. My approach is always centered around optimizing performance and achieving scalability, ensuring that every project I undertake enhances business operations and drives success.
                    
                </p>
                <a href="#contact" className="btn btn-primary">Let's Talk</a>
            </div>
            
        </div>

        </section>
    )
}

export default About