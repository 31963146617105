import React from "react";
import { BsPatchCheckFill } from "react-icons/bs";
import './experience.css'

const Experience = () => {
    return (
        <section id="experience">
            <h5>Skills I Have</h5>
            <h2>My Experience</h2>
            <div className="container experience__container">
                <div className="experience__frontend">
                    <h3>Cloud</h3>
                    <div className="experience__content">
                        <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>AWS</h4>
                        <small className="text-light">Experienced</small>
                        </div>
                        </article>


                        <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>GCP</h4>
                        <small className="text-light">Intermediate</small>
                        </div>
                        </article>


                        <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>Kubernetes</h4>

                        <small className="text-light">Intermediate</small>
                        </div>
                        </article>


                        <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>Docker</h4>
                        <small className="text-light">Experienced</small>
                        </div>
                        </article>


                        <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>Terraform</h4>
                        <small className="text-light">Intermediate</small>
                        </div>
                        </article>

                        <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>Ansible</h4>
                        <small className="text-light">Intermediate</small>
                        </div>
                        </article>
                    </div>

                </div>
                <div className="experience__backend">
                    <h3>Backend Development</h3>
                    <div className="experience__content">
                        <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>Python</h4>
                        <small className="text-light">Experienced</small>
                        </div>
                        </article>


                        <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>Django</h4>
                        <small className="text-light">Experienced</small>
                        </div>
                        </article>


                        <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>Flask</h4>

                        <small className="text-light">Experienced</small>
                        </div>
                        </article>


                        <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>FastAPI</h4>
                        
                        <small className="text-light">Experienced</small>
                        </div>
                        </article>


                        <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>MongoDB</h4>
                        <small className="text-light">Experienced</small>
                        </div>
                        </article>

                        <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>SQL</h4>
                        <small className="text-light">Experienced</small>
                        </div>
                        </article>
                    </div>
                    
                </div>
                <div className="experience__backend">
                    <h3>Frontend</h3>
                    <div className="experience__content">
                        <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>HTML</h4>
                        <small className="text-light">Experienced</small>
                        </div>
                        </article>


                        <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>CSS</h4>
                        <small className="text-light">Intermediate</small>
                        </div>
                        </article>


                        <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>JavaScript</h4>

                        <small className="text-light">Intermediate</small>
                        </div>
                        </article>


                        <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>React JS</h4>
                        
                        <small className="text-light">Experienced</small>
                        </div>
                        </article>


                        {/* <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>MongoDB</h4>
                        <small className="text-light">Experienced</small>
                        </div>
                        </article>

                        <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>SQL</h4>
                        <small className="text-light">Intermediate</small>
                        </div>
                        </article> */}
                    </div>
                    
                </div>
                <div className="experience__backend">
                    <h3>Machine Learning</h3>
                    <div className="experience__content">
                        <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>Tensorflow/Keras</h4>
                        <small className="text-light">Intermediate</small>
                        </div>
                        </article>


                        <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>PyTorch</h4>
                        <small className="text-light">Intermediate</small>
                        </div>
                        </article>


                        <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>Langchain</h4>

                        <small className="text-light">Intermediate</small>
                        </div>
                        </article>


                        <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>GPT APIs</h4>
                        
                        <small className="text-light">Experienced</small>
                        </div>
                        </article>


                        
                    </div>
                    
                </div>
            </div>
        </section>
    )
}

export default Experience