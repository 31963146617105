import React from "react";
import { BiCheck } from "react-icons/bi";
import './services.css'

const Services = () => {
    return (
        <section id="services">
    <h5>What I Offer</h5>
    <h2>Services</h2>
    <div className="container services__container">
        <article className="service">
            <div className="service__head">
                <h3>Development Operations</h3>
            </div>
            <ul className="service__list">
                <li><BiCheck className="service__list-icon"/>
                    <p>Designing and managing Cloud Infrastructure in AWS and GCP</p>
                </li>
                <li><BiCheck className="service__list-icon"/>
                    <p>Automating infrastructure with Terraform and Ansible scripts</p>
                </li>
                <li><BiCheck className="service__list-icon"/>
                    <p>Dockerizing applications and managing containers using AWS ECR and Kubernetes</p>
                </li>
                <li><BiCheck className="service__list-icon"/>
                    <p>Setting up CI/CD pipelines for automated build and deployment processes</p>
                </li>
                <li><BiCheck className="service__list-icon"/>
                    <p>Integrating cloud services for optimized performance and scalability</p>
                </li>
            </ul>
        </article>
        <article className="service">
            <div className="service__head">
                <h3>Backend Development</h3>
            </div>
            <ul className="service__list">
                <li><BiCheck className="service__list-icon"/>
                    <p>Building robust backend systems with Django and Flask</p>
                </li>
                <li><BiCheck className="service__list-icon"/>
                    <p>Creating and managing RESTful APIs for seamless frontend-backend integration</p>
                </li>
                <li><BiCheck className="service__list-icon"/>
                    <p>Implementing authentication and authorization using OAuth and JWT</p>
                </li>
                <li><BiCheck className="service__list-icon"/>
                    <p>Configuring database solutions for both SQL (PostgreSQL, MySQL) and NoSQL (MongoDB, DynamoDB) databases</p>
                </li>
                <li><BiCheck className="service__list-icon"/>
                    <p>Processing ETL jobs and automated tasks with Celery and Apache Airflow</p>
                </li>
            </ul>
        </article>
        <article className="service">
            <div className="service__head">
                <h3>Machine Learning</h3>
            </div>
            <ul className="service__list">
                <li><BiCheck className="service__list-icon"/>
                    <p>Designing and training machine learning models for predictive analytics and decision making</p>
                </li>
                <li><BiCheck className="service__list-icon"/>
                    <p>Developing specialized models for image processing and natural language processing</p>
                </li>
                <li><BiCheck className="service__list-icon"/>
                    <p>Applying transfer learning to leverage state-of-the-art pre-trained models</p>
                </li>
                <li><BiCheck className="service__list-icon"/>
                    <p>Optimizing ML models for performance in real-time applications</p>
                </li>
                <li><BiCheck className="service__list-icon"/>
                    <p>Fine-tuning state-of-the-art large language models such as GPT for tailored business applications, enhancing decision-making and automation.</p>
                </li>
            </ul>
        </article>
    </div>
</section>

    )
}

export default Services