import React from "react";
import './portfolio.css'
import IMG1 from "../../assets/simpleurl.png"
import IMG2 from "../../assets/u600mgmt.png"

import IMG3 from "../../assets/sdrive.png"

import IMG4 from "../../assets/btstats.png"

import IMG5 from "../../assets/aihealth.png"
import IMG6 from "../../assets/ipllive.png"
import INF from "../../assets/imagenotfound.jpg"


const data = [
    {
        id: 1,
        image: IMG1,
        title: "A Simple URL shortner",
        github: "https://github.com/rsumit123/stock_market_game",
        demo: "https://simple-url.skdev.one/"
    },
    {
        id: 2,
        image: IMG2,
        title: "Solution to management of battrick cricket games",
        github: "https://github.com/rsumit123/u600_management",
        demo: "https://u600.skdev.one/"
    },
    {
        id: 3,
        image: IMG5,
        title: "A mental health chatbot to help people in need quickly",
        github: "https://github.com/rsumit123/ai-health-client",
        demo: "https://ai-health.vercel.app/"
    },
    {
        id: 4,
        image: IMG4,
        title: "A stats app that shows the stats of the Battrick matches",
        github: "https://github.com/rsumit123/battrick_stats",
        demo: "https://www.skdev.one/"
    },
    {
        id: 5,
        image: IMG3,
        title: "A google drive like file store with file archive option",
        github: "https://github.com/rsumit123/sdrive_api",
        demo: "https://sdrive.skdev.one"
    },
    {
        id: 6,
        image: IMG6,
        title: "A Live Api to show the ongoing cricket matches and their scores",
        github: "https://github.com/rsumit123/ipl_live",
        demo: "https://cric-score.skdev.one/"
    }
]

const Portfolio = () => {
    return (
        <section id="portfolio">
            <h5>My Recent Work</h5>
            <h2>Portfolio</h2>
            <div className="container portfolio__container">
                {
                    data.map(({id,image, title, github, demo}) =>{
                        return (
                            <article key={id} className="portfolio__item">
                    <div className="portfolio__item-image">
                        <img src={image} alt={title}></img>


                    </div>
                    <h3>{title}</h3>
                    <div className="portfolio__item-cta">
                    <a href={github} target="__blank" className="btn">Github</a>
                    <a href={demo} className="btn btn-primary" target="__blank">Live Demo</a>
                    </div>
                </article>
                        )
                    } )
                }
                
                
                
                </div>

        </section>
    )
}

export default Portfolio